<template>
    <div v-if="shouldShowSpinner" class="two-factor-spinner">
        <tip-spinner></tip-spinner>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'TwoFactorSpiner',
    data() {
        return {
            shouldShowSpinner: false
        };
    },
    computed: {
        ...mapState('twoFactor', ['isLoading'])
    },
    watch: {
        isLoading(value) {
            this.setShowSpinnerIfNeededAfterDelay(value);
        }
    },
    mounted() {
        this.setShowSpinnerIfNeededAfterDelay(this.isLoading);
    },
    methods: {
        setShowSpinnerIfNeededAfterDelay(isLoading) {
            const delay = 1000;
            if (isLoading) {
                setTimeout(() => {
                    this.shouldShowSpinner = this.isLoading;
                }, delay);
            } else {
                this.shouldShowSpinner = false;
            }
        }
    }
};
</script>

<style lang="scss">
.two-factor-spinner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
}
</style>
